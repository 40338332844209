import {
  Button,
  InputBase,
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
  Typography,
  Container,
  InputLabel,
  FormControl
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
//import { client } from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'context/Keycloak/Keycloak';
import { GetCompanyCloseLogos } from 'utils/Constants';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Open Sans"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

export default function SignIn(props: any) {
  const { t } = useTranslation<string>();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password')
    });
  };

  const { login } = useKeycloak();

  return (
    <>
      <div className='bodyWrapper'></div>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 0 50px #ccc',
            padding: 5,
            background: '#fff'
          }}>
          <Typography sx={{ display: 'none' }} className='loginHeader'>
            {' '}
            {t('signin')}
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth sx={{ mb: 3, display: 'none' }}>
              <InputLabel shrink htmlFor='bootstrap-input'>
                {t('email')}
              </InputLabel>
              <BootstrapInput id='bootstrap-input' />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 3, display: 'none' }}>
              <InputLabel shrink htmlFor='bootstrap-password'>
                {t('password')}
              </InputLabel>
              <BootstrapInput id='bootstrap-password' />
            </FormControl>
            <FormControlLabel
              sx={{ display: 'none' }}
              control={<Checkbox value='remember' color='primary' />}
              label={<Typography sx={{ fontSize: 13 }}> {t('rememberme')}</Typography>}
            />
            <Stack spacing={2} sx={{ pb: 3 }} direction='column'>
              <GetCompanyCloseLogos theme={props.theme} width='120' height='120' />
            </Stack>
            <Stack spacing={2} direction='column'>
              <Button variant='contained' onClick={() => login()} className='loginCTA'>
                {t('login')}
              </Button>
              <Button sx={{ display: 'none' }} variant='text' className='forgotPassword'>
                {t('forgotPassword')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}
