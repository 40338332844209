import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { StyledButton } from './ButtonStyle';
import { IButtonProps } from './IButtonProps';

export const AddButton = (props: IButtonProps) => {
  const { buttonText, isDisable, variant } = props;

  return (
    <StyledButton disabled={isDisable} variant={variant} startIcon={<AddCircleOutlinedIcon />}>
      {buttonText}
    </StyledButton>
  );
};

export default AddButton;
