import { Card, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Box from '@mui/material/Box';
import { ICardProps } from './ICardProps';
import { cardStyle } from './CardStyle';

const NoticeCard = (props: ICardProps) => {
  const handleIconClick = () => {
    window.location.href = props.url || '';
  };

  return (
    <Box sx={{ width: '300px' }}>
      <Card sx={cardStyle} className={props.cardType}>
        <CardContent>
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
            <Stack>
              <DescriptionOutlinedIcon className='DescpOutlineIconStyle' />
            </Stack>
            <Stack>
              <Typography>{props.title}</Typography>
              <Typography>{props.message}</Typography>
            </Stack>
          </Stack>
        </CardContent>
        <Stack className='KeyboardArrowRightStyle'>
          <KeyboardArrowRightOutlinedIcon onClick={handleIconClick} />
        </Stack>
      </Card>
    </Box>
  );
};

export default NoticeCard;
