import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PolicyService from 'entities/Policy/PolicyService';
import { useTheme } from '@mui/material/styles';
import { IPolicy } from 'entities/Policy/IPolicy';
import { contentText, getCurrencySymbol } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';

interface IPolicyBriefViewProps {
  policyNumber: string;
}

export const PolicyBriefView = (props: IPolicyBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [policyDetails, setPolicyDetails] = useState<IPolicy>();
  const service = new PolicyService();

  const getPolicyDetails = () => {
    return service
      .get(props.policyNumber ?? '')
      .then((policy: any) => {
        setPolicyDetails(policy.policyMessage.Policy[0]);
        setisloading(false);
      })
      .catch((error: any) => {
        console.error('Error fetching Policy details:', error);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.policyNumber && getPolicyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.policyNumber]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading} onClick={() => {}}>
        <Loader></Loader>
      </Backdrop>

      <Box sx={{ p: 3 }}>
        <Stack direction={'row'} spacing={1} alignItems='center'>
          <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
            <Typography>PD</Typography>
          </Avatar>

          <Stack direction='column' spacing={1}>
            <Typography sx={{ fontSize: '24px', color: (theme) => theme.palette.primary.main, fontWeight: 'bold' }}>
              {policyDetails?.LeadInsurerName}
            </Typography>

            <Typography sx={{ fontWeight: 800, fontSize: '14px', color: (theme) => theme.palette.primary.main }}>
              #{policyDetails?.CompanysPolicyNumber || 'N/A'}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={1} sx={{ mt: 1, mb: 3 }}>
          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('insuranceDetails')}
          </Typography>

          <Grid container spacing={2}>
            {policyDetails?.CompanysPolicyNumber && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('policyNumber')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}> {policyDetails?.CompanysPolicyNumber || 'N/A'}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.LOBDesc && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('lOBDesc')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}> {policyDetails?.LOBDesc || 'N/A'}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.LeadInsurerName && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('leadInsurerName')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{policyDetails?.LeadInsurerName}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.ItemIdInfo?.AgencyId?.Value && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('contactPerson')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}> {policyDetails?.ItemIdInfo?.AgencyId?.Value}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.OriginalPolicyInceptionDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('originalPolicyInceptionDt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}> {policyDetails?.OriginalPolicyInceptionDt}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.ContractTerm?.ExpirationDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('expirationDt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{policyDetails?.ContractTerm?.ExpirationDt}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.ContractTerm?.DurationPeriod?.NumUnits && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('contractTerm')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{policyDetails?.ContractTerm?.DurationPeriod?.NumUnits}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('paymentMethod')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {policyDetails?.PaymentOption?.[0]?.MethodPaymentRefCd?._Desc}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          {policyDetails?.LOBCd !== 'PKG' && (
            <>
              <Divider />
              <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
                {t('coverageDetails')}
              </Typography>

              <Grid container spacing={2}>
                {policyDetails?.DwellFireLineBusiness?.Coverage?.[0]?.CoverageDesc && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('coverageDesc')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>
                        {policyDetails?.DwellFireLineBusiness?.Coverage?.[0]?.CoverageDesc}
                      </Typography>
                    </Grid>
                  </>
                )}

                {policyDetails?.DwellFireLineBusiness?.Coverage?.[0]?.DepositPremiumAmt?.Amt && (
                  <>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>{t('depositPremiumAmt')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={contentText}>
                        {getCurrencySymbol(
                          policyDetails?.DwellFireLineBusiness?.Coverage?.[0]?.DepositPremiumAmt?.CurCd?.Value ?? ''
                        )}
                        {policyDetails?.DwellFireLineBusiness?.Coverage?.[0]?.DepositPremiumAmt?.Amt}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}

          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('paymentDetails')}
          </Typography>

          <Grid container spacing={2}>
            {policyDetails?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('billingPeriod')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {policyDetails?.PaymentOption?.[0]?.BillingPeriod?.DurationPeriod?.NumUnits}
                  </Typography>
                </Grid>
              </>
            )}

            {policyDetails?.GrossPremiumAmt?.Amt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('grossPremiumAmt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {getCurrencySymbol(policyDetails?.GrossPremiumAmt?.CurCd?.Value ?? '')}
                    {policyDetails?.GrossPremiumAmt?.Amt}
                  </Typography>
                </Grid>
              </>
            )}

            {policyDetails?.TaxAmt?.Amt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('taxAmt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {getCurrencySymbol(policyDetails?.TaxAmt?.CurCd?.Value ?? '')} {policyDetails?.TaxAmt?.Amt}
                  </Typography>
                </Grid>
              </>
            )}

            {policyDetails?.RenewalPremiumAmt?.Amt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('renewalPremiumAmt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {getCurrencySymbol(policyDetails?.RenewalPremiumAmt?.CurCd?.Value ?? '')}
                    {policyDetails?.RenewalPremiumAmt?.Amt}
                  </Typography>
                </Grid>
              </>
            )}

            {policyDetails?.Account?.AccountNumberId?.Value && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('ibanNumber')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{policyDetails?.Account?.AccountNumberId?.Value}</Typography>
                </Grid>
              </>
            )}

            {policyDetails?.OriginalPolicyInceptionDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('originalPolicyInceptionDt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{policyDetails?.OriginalPolicyInceptionDt}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Divider />
        </Stack>

        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography
              variant='subtitle2'
              display='block'
              sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
              gutterBottom>
              <Link to={``} style={{ color: theme.palette.secondary.main }}>
                {t('moreDetails')}
              </Link>
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography
              variant='subtitle2'
              display='block'
              sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
              gutterBottom>
              <Link to={``} style={{ color: theme.palette.secondary.main }}>
                {t('edit')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PolicyBriefView;
