import { Grid, Typography } from '@mui/material';
import { countries } from './Constants';
import { Theme } from '@mui/material/styles';

export const keyGenerator = () => '_' + Math.random().toString(36).substr(2, 9);

export const getCountryCode = (input: any) => {
  const arr = countries.find((itm) => itm.phone === input);
  return arr?.code.toLowerCase() || 'ad';
};

export function getStyles(theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

export const contentText = {
  color: '#00005A',
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

export const getPhoneNumber = (itm: any) => {
  return (
    <>
      {itm
        ? itm.map((item: any, index: any) => (
            <Grid container spacing={2} key={index} item xs={12} alignItems='center'>
              <Grid item xs={6}>
                <Typography sx={contentText}>{item.PhoneTypeCd}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={contentText}>{item.PhoneNumber}</Typography>
              </Grid>
            </Grid>
          ))
        : ''}
    </>
  );
};

export const getCurrencySymbol = (currencyCode: string) => {
  const currencySymbols: { [key: string]: string } = { EUR: '€ ' };
  return currencySymbols[currencyCode] || '';
};
