import { updateToken, getToken, isTokenExpired } from 'context/Keycloak/Keycloak';

export const Formioprerequest = {
  priority: 0,
  preRequest: async (requestArgs: any) => {
    const promise = isTokenExpired(30) ? updateToken() : Promise.resolve();

    return promise.then(() => {
      requestArgs.opts.headers = {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type':"application/json"
      };
    });
  }
};
