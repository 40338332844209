import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { State } from 'store/store';
import { Button } from '@mui/material';
import IconRemove from 'component/atoms/Icons/IconRemove';

// Define the drawerWidth here
const drawerWidth = 500;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: open ? 0 : -drawerWidth, // Adjusted marginRight conditionally
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),

  position: 'relative'
}));

const BriefViewDrawer = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: State) => state.briefViewDrawer.open);
  const briefViewComponent = useSelector((state: State) => state.briefViewDrawer.briefViewComponent);

  const handleDrawerClose = () => {
    dispatch(setOpenBriefView(false));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Main open={open}> </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          },
          '@media (max-width: 600px)': {
            // Adjust width for smaller screens
            width: '50%',
            '& .MuiDrawer-paper': {
              width: '50%'
            }
          }
        }}
        variant='persistent'
        anchor='right'
        open={open}>
        <Box sx={{ position: 'absolute', right: 0, top: 2 }}>
          <Button onClick={handleDrawerClose} sx={{ border: 'none', background: 'none', cursor: 'pointer', p: 3 }}>
            <IconRemove width='25' height='25' color='rgba(0,0,90,.2)' />
          </Button>
        </Box>
        <Divider />
        {briefViewComponent}
        <Divider />
      </Drawer>
    </Box>
  );
};

export default BriefViewDrawer;
