import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Button, Grid, Typography } from '@mui/material';
import TabPanel from 'component/organisms/TabPanel/TabPanel';
import { useParams } from 'react-router-dom';
import { PartyService } from 'entities/Party/PartyService';
import PolicyService from 'entities/Policy/PolicyService';
import ClaimService from 'entities/Claim/ClaimService';
import PolicyListView from 'component/organisms/PolicyListView/PolicyListView';
import FileListView from 'component/organisms/FileListView/FileListView';
import ArchiveService from 'entities/Archive/ArchiveService';
import ClaimsListView from 'component/organisms/ClaimsListView/ClaimsListView';
import { Link } from 'react-router-dom';
import PartyBriefView from './PartyBriefView';
import { useDispatch } from 'react-redux';
import { setBriefView, setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import { Action } from '../Action/Action';
import Loader from 'component/organisms/Loader/Loader';
import OfferListView from 'component/organisms/OfferListView/OfferListView';

const PartyDetails = () => {
  const { t } = useTranslation<string>();
  const { partyNumber, module } = useParams();
  const [policyData, setPolicyData] = React.useState<any>([]);
  const [offerData, setOfferData] = React.useState<any>([]);
  const [archivesData, setArchivesData] = React.useState<any>([]);
  const [claimsData, setClaimsData] = React.useState<any>([]);
  const [partyDetails, setPartyDetails] = useState<any>({});
  const dispatch = useDispatch();
  const [isloading, setisloading] = useState(true);

  const itemOptions = [
    t('file'),
    t('insurances'),
    t('claims'),
    t('offers'),
    t('atlook'),
    t('action')];

  async function fetchPartyDetails(search: string) {
    try {
      const service = new PartyService();
      const response: any = await service.get(search);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPolices(search: string) {
    try {
      const service = new PolicyService();
      const policies = await service.search('PartyNumber', search);
      const policiesWithId = policies.map((policy: any, index: number) => ({ ...policy, id: index + 1 }));
      setPolicyData(policiesWithId);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchArchives(search: string) {
    try {
      const service = new ArchiveService();
      const archives = await service.search('PartyNumber', search);
      const archivesWithId = archives.map((archives: any, index: number) => ({ ...archives, id: index + 1 }));
      setArchivesData(archivesWithId);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchClaims(search: string) {
    try {
      const service = new ClaimService();
      const claims = await service.search('PartyNumber', search);
      const claimsWithId = claims.map((claims: any, index: number) => ({ ...claims, id: index + 1 }));
      setClaimsData(claimsWithId);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchOffers(search: string) {
    try {
      const service = new PolicyService();
      const offers = await service.search('PartyNumber', search + '&PolicyVersion=Q');
      const offersWithId = offers.map((policy: any, index: number) => ({ ...policy, id: index + 1 }));
      setOfferData(offersWithId);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchCall = async () => {
      const response = await fetchPartyDetails(partyNumber as string);
      setPartyDetails({ ...response });
      await fetchPolices(partyNumber as string);
      await fetchArchives(partyNumber as string);
      await fetchClaims(partyNumber as string);
      await fetchOffers(partyNumber as string);
      setisloading(false);
    };
    fetchCall();
  }, [partyNumber]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading} onClick={() => {}}>
        <Loader></Loader>
      </Backdrop>
      <Box sx={{ width: '100%' }}>
        <Action />
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} md={9}>
            <Typography variant='h4' sx={{ color: (theme: any) => theme.palette.primary.main, fontWeight: 'bold' }}>
              {partyDetails?.Surname} {partyDetails?.PreferredName}{' '}
              <span>
                <Button
                  component={Link}
                  to={``}
                  variant='text'
                  color='secondary'
                  sx={{
                    textDecoration: 'underline',
                    textTransform: 'none',
                    cursor: 'pointer',
                    marginLeft: '5px'
                  }}
                  onClick={() => {
                    dispatch(setBriefView(<PartyBriefView partyNumber={String(partyDetails?.PartyNumber)} />));
                    dispatch(setOpenBriefView(true));
                  }}>
                  {t('details')}
                </Button>
              </span>
            </Typography>

            <Typography
              variant='subtitle1'
              sx={{ fontWeight: 800, color: (theme) => theme.palette.primary.main, mt: 1 }}
              gutterBottom>
              #{partyDetails?.PartyNumber || 'N/A'}
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          <TabPanel itemOptions={itemOptions} partyNumber={partyDetails?.PartyNumber} tab={Number(module)}>
            {Number(module) === 0 &&
              (archivesData.length ? (
                <FileListView rows={archivesData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')}</Box>
              ))}
            {Number(module) === 1 &&
              (policyData.length ? (
                <PolicyListView rows={policyData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}
            {Number(module) === 2 &&
              (claimsData.length ? (
                <ClaimsListView rows={claimsData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}
            {Number(module) === 3 &&
              (offerData.length ? (
                <OfferListView rows={offerData} />
              ) : (
                <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>
              ))}

            {Number(module) === 4 && <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}> {t('noRecords')} </Box>}
            {Number(module) === 5 && <Box sx={{ p: 5, border: 'solid thin #c3c3c3' }}>{t('noRecords')} </Box>}
          </TabPanel>
        </Grid>
      </Box>
    </>
  );
};

export default PartyDetails;
