import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClaimService from 'entities/Claim/ClaimService';
import { IClaim } from 'entities/Claim/IClaim';
import { useTheme } from '@mui/material/styles';
import { contentText } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';

interface IClaimBriefViewProps {
  claimNumber: string;
}

export const ClaimBriefView = (props: IClaimBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [claimDetails, setClaimDetails] = useState<IClaim>();
  const service = new ClaimService();

  const getClaimDetails = () => {
    return service
      .get(props.claimNumber ?? '')
      .then((claim: any) => {
        setClaimDetails(claim);
        setisloading(false);
      })
      .catch((error: any) => {
        console.error('Error fetching claim details:', error);
        setisloading(false); // Set loading to false in case of error
      });
  };

  useEffect(() => {
    setisloading(true);
    props.claimNumber && getClaimDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.claimNumber]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading} onClick={() => {}}>
        <Loader></Loader>
      </Backdrop>
      <Box sx={{ p: 3 }}>
        <Stack direction={'row'} spacing={1} alignItems='center'>
          <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
            <Typography>CD</Typography>
          </Avatar>
          <Stack direction='column' spacing={1}>
            <Typography sx={{ fontSize: '24px', color: (theme) => theme.palette.primary.main, fontWeight: 'bold' }}>
              {claimDetails?.ClaimsParty[1]?.Surname}
            </Typography>

            <Typography sx={{ fontWeight: 800, fontSize: '14px', color: (theme) => theme.palette.primary.main }}>
              #{claimDetails?.ClaimsOccurrence.ClaimNumber || 'N/A'}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={1} sx={{ mt: 1, mb: 3 }}>
          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('claimdetails')}
          </Typography>

          <Grid container spacing={2}>
            {claimDetails?.ClaimsOccurrence.ClaimNumber && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('claimNumber')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsOccurrence.ClaimNumber || 'N/A'}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsOccurrence.CompanysClaimNumber && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('companysClaimNumber')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {claimDetails?.ClaimsOccurrence.CompanysClaimNumber || 'N/A'}
                  </Typography>
                </Grid>
              </>
            )}

            {claimDetails?.Policy.CompanysPolicyNumber && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('companysPolicyNumber')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.Policy.CompanysPolicyNumber}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsOccurrence.ClaimsReported[0]?.ReportedDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('claimsReportedDt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {claimDetails?.ClaimsOccurrence.ClaimsReported[0]?.ReportedDt}
                  </Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsOccurrence.ClaimStatusCd && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('claimStatusCd')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsOccurrence.ClaimStatusCd}</Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('lossDetails')}
          </Typography>
          <Grid container spacing={2}>
            {claimDetails?.ClaimsOccurrence.LossDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('lossDt')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsOccurrence.LossDt}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsOccurrence.LossTime && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('lossTime')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsOccurrence.LossTime}</Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('partyDetails')}
          </Typography>
          <Grid container spacing={2}>
            {claimDetails?.ClaimsParty[1]?.Surname && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('surName')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsParty[1]?.Surname}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsParty[1]?.BirthDt && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('dob')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsParty[1]?.BirthDt}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsParty[1]?.Communications?.[0]?.EmailAddr && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('emailAddress')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>
                    {claimDetails?.ClaimsParty[1]?.Communications?.[0]?.EmailAddr}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Divider />
          <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
            {t('addressDetails')}
          </Typography>
          <Grid container spacing={2}>
            {claimDetails?.ClaimsParty[1]?.Addr?.[0]?.PostalCode && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('postalCode')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsParty[1]?.Addr?.[0]?.PostalCode}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsParty[1]?.Addr?.[0]?.Addr1 && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('addr1')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsParty[1]?.Addr?.[0]?.Addr1}</Typography>
                </Grid>
              </>
            )}

            {claimDetails?.ClaimsParty[1]?.Addr?.[0]?.City && (
              <>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{t('city')}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={contentText}>{claimDetails?.ClaimsParty[1]?.Addr?.[0]?.City}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Divider />
        </Stack>

        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography
              variant='subtitle2'
              display='block'
              sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
              gutterBottom>
              <Link to={``} style={{ color: theme.palette.secondary.main }}>
                {t('moreDetails')}
              </Link>
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography
              variant='subtitle2'
              display='block'
              sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
              gutterBottom>
              <Link to={``} style={{ color: theme.palette.secondary.main }}>
                {t('edit')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
