import React, { useState } from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ccsTheme, klvTheme } from './theme/theme';
import { DesignSystem } from './temp/DesignSystem/DesignSystem';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import FormRenderer from 'component/molecules/Formrenderer/FormRenderer';
import { useKeycloak } from 'context/Keycloak/Keycloak';
import Layout from 'component/pages/Layout/Layout';
import { useTranslation } from 'react-i18next';
import Login from 'component/pages/Login/Login';
import { LoaderProvider } from 'context/Loader/Loader';
import { Action } from 'component/pages/Action/Action';
import config from 'config/config';
import PartyDetails from 'component/pages/Party/PartyDetails';
import Underconstruction from 'component/pages/UnderConstruction/Underconstruction';
import Translation from 'component/pages/Translation/Translation';
//import Welcomescreen from 'component/pages/Layout/Welcomescreen';

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const { initialized, authenticated } = useKeycloak();
  const { i18n } = useTranslation<string>();

  const [theme, setTheme] = useState<Theme>(ccsTheme);
  const [themeVal, setThemeVal] = useState<string>('CCS Theme');

  const handleTheme = (event: any) => {
    const root: any = document.querySelector(':root');
    if (event.target.value === 'CCS Theme') {
      setTheme(ccsTheme);
      root?.style?.setProperty('--theme-color', '#0046ff');
    }
    if (event.target.value === 'KVL Theme') {
      setTheme(klvTheme);
      root?.style?.setProperty('--theme-color', '#31912e');
    }
    setThemeVal(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <CssBaseline></CssBaseline>
        <Router basename={config.basename}>
          {initialized() && !authenticated() && <Login theme={themeVal} />}
          {authenticated() && (
            <LoaderProvider>
              <Layout handleTheme={handleTheme} theme={themeVal}>
                <Routes>
                  <Route index path='/' element={<Action />} />
                  <Route path='action' element={<Action />} />
                  <Route path='insurances' element={<Underconstruction />} />
                  <Route path='offer' element={<Underconstruction />} />
                  <Route path='offers' element={<Underconstruction />} />
                  <Route path='tasks' element={<Underconstruction />} />
                  <Route path='claim' element={<Underconstruction />} />
                  <Route path='language' element={<Translation />} />
                  <Route path='party/partydetails/:partyNumber/:module' element={<PartyDetails />} />
                  {/* <Route path='tasks' element={<ActionsDashboard />} /> */}
                  <Route path='formrenderer/:formsrc/:partynumber' element={<FormRenderer lang={i18n.language} />} />
                  <Route path='formrenderer/:formsrc' element={<FormRenderer lang={i18n.language} />} />
                  <Route path='designsys' element={<DesignSystem handleTheme={handleTheme} theme={themeVal} />} />
                </Routes>
              </Layout>
            </LoaderProvider>
          )}
        </Router>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default App;
