import './Underconstruction.css'; // Import your CSS file for styling
import imgSrc from '../../atoms/Icons/underUC.png';
import { Box, Typography } from '@mui/material';
import { Action } from '../Action/Action';

function Underconstruction() {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Action></Action>
      </Box>
      <Box>
        <Box>
          <div className='svg-container'>
            {/* Your SVG element goes here */}
            <img alt='sample' src={imgSrc}></img>
            <Typography sx={{ fontSize: '20px', color: '#ccc', textTransform: 'uppercase', mt: 4 }}>
              Page Under Construction
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default Underconstruction;
