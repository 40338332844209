import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

import EntityListView from '../EntityListView/EntityListView';
import { IPolicy } from 'entities/Policy/IPolicy';
import { Box } from '@mui/material';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface IPolicyListViewProps {
  rows: IPolicy[];
}

//===============================================
// Component render function
//===============================================nameofInsurer

const PartyListView = (props: IPolicyListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
        valueGetter: (params: any) => params.row.id
      },
      {
        field: 'LeadInsurerName',
        headerName: t('nameofInsurer'),
        type: 'string',
        flex: 2,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              <IconInsuranceCar2></IconInsuranceCar2>
            </Box>
            {params.value}
          </>
        )
      },
      {
        field: 'CompanysPolicyNumber',
        headerName: t('policyNumber'),
        type: 'string',
        flex: 1
      },

      { field: 'RateEffectiveDt', headerName: t('date'), type: 'string', flex: 2 },
      { field: 'Characteristic', headerName: t('referenceCharacter'), type: 'string', flex: 1 }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return <EntityListView columns={columns} rows={rows} getId={(row) => row.id} />;
};

export default PartyListView;
