import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import en from '../../../config/lang/en.png';
import nl from '../../../config/lang/nl.png';
//import de from '../../../config/lang/de.png';
import i18n from 'config/lang/i18n';

export const countriesLang = [
  {
    label: 'en',
    src: en,
    value: 'en'
  },
  {
    label: 'nl',
    src: nl,
    value: 'nl'
  }
];

export default function Language() {
  const langLocal = localStorage.getItem('language');
  const [countryLang, setCountryLang] = useState(langLocal || 'en');
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setCountryLang(event.target.value as string);
    localStorage.setItem('language', event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setCountryLang(langLocal || ('en' as string));
    i18n.changeLanguage(langLocal || ('en' as string));
  }, [langLocal]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 50 }}>
        <Select
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={countryLang}
          onChange={handleChange}>
          {countriesLang.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              <img src={option.src} alt={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
