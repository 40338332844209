import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import briefViewDrawerReducer from './briefview/briefViewDrawerSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    briefViewDrawer: briefViewDrawerReducer
  }
});

export const callSelector = <T>(selector: (state: State) => T) => {
  const state = store.getState();
  return selector(state);
};

export type Dispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
