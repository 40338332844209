import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { AccountMenuItemProps, AccountMenuProps } from './IAccountMenuProps';
import { accountMenuStyle } from './AccountMenuStyle';

const AccountMenuItem: React.FC<AccountMenuItemProps> = ({ text, onClick }) => (
  <MenuItem onClick={onClick}>{text}</MenuItem>
);

const AccountMenu: React.FC<AccountMenuProps> = ({ menuItems, buttonLabel = 'Menu' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{ ml: 2 }}>
        <Typography variant='body1' fontWeight='bold'>
          {buttonLabel}
        </Typography>
      </Button>
      <Menu
        sx={accountMenuStyle}
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}>
        {menuItems.map((item, index) => (
          <AccountMenuItem key={index} {...item} />
        ))}
      </Menu>
    </>
  );
};

export default AccountMenu;
