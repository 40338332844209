import EntityService from 'entities/EntityService';
import { IPolicy } from './IPolicy';

type PolicySearchFilter = 'PartyNumber';

//==============================================================
// Policy EntityService
//==============================================================

export class PolicyService extends EntityService<IPolicy, PolicySearchFilter> {
  protected flat(data: any) {
    return data?.Policies?.flatMap((i: any) => i.Policy) ?? [];
  }

  protected getId(item: IPolicy) {
    return item.PolicyNumber;
  }

  protected getPath() {
    return '/policy/v4/policies';
  }
}

export type { PolicySearchFilter };
export default PolicyService;
