export const cardStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 399,
  height: 72,
  '&.light.MuiPaper-root.MuiPaper-elevation': {
    color: (theme: any) => theme.palette.text.main,
    backgroundColor: (theme: any) => theme.palette.background.default
  },
  '&.dark.MuiPaper-root': {
    color: (theme: any) => theme.palette.primary.contrastText,
    backgroundColor: (theme: any) => theme.palette.primary.main
  },
  '& .KeyboardArrowRightStyle': {
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: '5px',
    fontSize: 'medium'
  },
  '& .MuiSvgIcon-root.DescpOutlineIconStyle': {
    borderRadius: '50%',
    backgroundColor: (theme: any) => theme.palette.secondary.main,
    padding: '8px',
    fontSize: '40px'
  }
};
