import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { keyGenerator } from '../../../utils/Utils';
import { countries } from '../../../utils/Constants';
import { getCountryCode } from '../../../utils/Utils';
import { InputLable } from 'component/organisms/InputLabel/InputLabel';

const randomKey = keyGenerator();

interface ICountryCode {
  label: string;
}

export default function CountryCode(props: ICountryCode) {
  return (
    <Autocomplete
      id='selectCountryCode'
      sx={{ width: 170, height: 20, '& .MuiInputBase-root': { py: '5px', px: 1 } }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.phone}
      renderOption={(props, option) => (
        <Box {...props} key={keyGenerator()} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0, fontSize: 14 } }}>
          <img
            loading='lazy'
            width='20'
            srcSet={`https://flagcdn.com/w40/${getCountryCode(option.phone)}.png 2x`}
            src={`https://flagcdn.com/w20/${getCountryCode(option.phone)}.png`}
            alt=''
          />
          ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <Box sx={{ display: 'inline-flex' }}>
          <FormControl sx={{ mt: 2 }} variant='standard'>
            <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
              {props.label}
            </InputLable>

            <TextField
              {...params}
              variant='outlined'
              className='countryCode'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <strong>
                      {' '}
                      <img
                        loading='lazy'
                        width='20'
                        srcSet={`https://flagcdn.com/w40/${getCountryCode(params.inputProps.value)}.png 2x`}
                        src={`https://flagcdn.com/w20/${getCountryCode(params.inputProps.value)}.png`}
                        alt=''
                      />
                    </strong>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Box>
      )}
    />
  );
}
