import { styled } from '@mui/material';
export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette?.['secondary']?.main,
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  background: '#fff',
  fontSize: 10,
  textTransform: 'uppercase',
  paddingTop: 10
}));

export const GroupItems = styled('ul')(({ theme }) => ({
  padding: 0,
  background: '#fff',
  fontSize: 14,
  color: theme.palette?.['primary']?.main,
  paddingBottom: 10
}));
export const MenuItemsIcon = styled('span')(({ theme }) => ({
  padding: 0,
  background: '#fff',
  fontSize: 14,
  color: theme.palette?.['secondary']?.main
}));

export const formControlWrapper = { width: '100%' };
export const autocompleteWrapper = {
  width: '100%',
  fontFamily: 'Open Sans',
  mr: 2,
  '.MuiFormControl-root .MuiOutlinedInput-root': { p: '10px' }
};
export const personIconStyle = { mr: 2, background: 'transparent' };
export const partyName = { mr: 2, fontWeight: 'bold' };
export const partyNumber = { mr: 2 };
export const partyDate = { mr: 2 };
export const dividerHeight = { h: 2 };
