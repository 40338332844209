import { useMemo } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import EntityListView from '../EntityListView/EntityListView';
import { IClaimListView } from 'entities/Claim/IClaim';
import { Box, Chip, alpha } from '@mui/material';
import IconInsuranceCar2 from 'component/atoms/Icons/IconInsuranceCar2';
import { useTranslation } from 'react-i18next';
import i18n from 'config/lang/i18n';
import IconHours from 'component/atoms/Icons/IconHours';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface IClaimsListViewProps {
  rows: IClaimListView[];
}

//===============================================
// Component render function
//===============================================nameofInsurer

const ClaimsListView = (props: IClaimsListViewProps) => {
  const { t } = useTranslation<string>();
  const { rows } = props;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
        valueGetter: (params: any) => params.row.id
      },
      {
        field: 'Claims',
        headerName: t('claims'),
        type: 'string',
        flex: 2,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              {' '}
              <IconInsuranceCar2></IconInsuranceCar2>
            </Box>
            {params.row.CompanysClaimNumber}
          </>
        )
      },
      {
        field: 'Date',
        headerName: t('date'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IClaimListView>) => params.row.ClaimsReported[0]?.ReportedDt
      },
      {
        field: 'PolicyNumber',
        headerName: t('polisNum'),
        type: 'string',
        flex: 2,
        valueGetter: (params: GridValueGetterParams<IClaimListView>) => params.row.PolicyNumber
      },
      {
        field: 'TimeRegisteration',
        headerName: t('timeRegisteration'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IClaimListView>) => params.row.LossTime,
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              {' '}
              <IconHours></IconHours>
            </Box>
            {params.row.LossTime}
          </>
        )
      },
      {
        field: 'ClaimStatusCd',
        headerName: t('status'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IClaimListView>) => {
          return params.row.ClaimStatusCd;
        },
        renderCell: (params) => (
          <>
            <Box sx={{ pr: 2 }}>
              {params.row.ClaimStatusCd === 'O' ? (
                <Chip
                  sx={{
                    background: (theme) => alpha(theme.palette.secondary.main, 0.15),
                    color: (theme) => theme.palette.secondary.main
                  }}
                  label={t('processing')}
                  color='secondary'
                  variant='filled'
                />
              ) : (
                <Chip
                  sx={{
                    background: (theme) => alpha(theme.palette.secondary.main, 0.15),
                    color: (theme) => theme.palette.secondary.main
                  }}
                  label={t('done')}
                  color='secondary'
                  variant='filled'
                />
              )}
            </Box>
          </>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <EntityListView
      columns={columns}
      rows={rows}
      getId={(row) => {
        return row?.id;
      }}
    />
  );
};

export default ClaimsListView;
