import { useMemo } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import i18n from '../../../config/lang/i18n';
import EntityListView from '../EntityListView/EntityListView';
import { IArchive } from 'entities/Archive/IArchive';
import { useTranslation } from 'react-i18next';
import IconInvoice from 'component/atoms/Icons/IconInvoice';

//===============================================
// private variables
//===============================================

//===============================================
// Component props interface
//===============================================

interface FileListViewProps {
  rows: IArchive[];
}

//===============================================
// Component render function
//===============================================

const FileListView = (props: FileListViewProps) => {
  const { rows } = props;

  const { t } = useTranslation<string>();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
        valueGetter: (params: any) => params.row.id
      },
      {
        field: 'ArchiveNumber',
        headerName: t('archiveNumber'),
        type: 'string',
        flex: 2,
        valueGetter: (params: GridValueGetterParams<IArchive>) => params.row.ArchiveNumber
      },
      {
        field: 'Date',
        headerName: t('CreationDt'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IArchive>) => params.row.FileAttachmentInfo.CreationDt
      },
      {
        field: 'AttachmentDesc',
        headerName: t('attachmentDesc'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IArchive>) => params.row.FileAttachmentInfo.AttachmentDesc
      },
      {
        field: 'AttachmentFilename',
        headerName: t('attachmentFilename'),
        type: 'string',
        flex: 2,
        valueGetter: (params: GridValueGetterParams<IArchive>) => params.row.FileAttachmentInfo.AttachmentFilename
      },
      {
        field: 'AttachmentType',
        headerName: t('documentType'),
        type: 'string',
        flex: 1,
        valueGetter: (params: GridValueGetterParams<IArchive>) => params.row.FileAttachmentInfo.MIMEContentTypeCd.Value,
        renderCell: (params) => (
          <>
            {' '}
            <span style={{ paddingRight: '10px', display: 'inline-block' }}>
              <IconInvoice></IconInvoice>{' '}
            </span>
            {params.value}
          </>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return <EntityListView columns={columns} rows={rows} getId={(row) => row.ArchiveNumber} />;
};

export default FileListView;
