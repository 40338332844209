import { FormControlLabel, Stack, Switch } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';

export const AntEyeSwitch = () => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
    alert('show Password : ' + !checked);
  };

  return (
    <Stack spacing={1} direction={'row'}>
      <FormControlLabel control={<Switch checked={checked} onChange={handleToggle} />} label='Show Password' />
      {checked && <VisibilityOutlinedIcon />}
      {!checked && <VisibilityOffOutlinedIcon />}
    </Stack>
  );
};

export default AntEyeSwitch;
