import React, { useEffect, useState } from 'react';
import { Form, Formio } from '@formio/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formioprerequest } from '../../../entities/Formioprerequest/Formioprerequest';
import { formSrcMapping } from '../../../utils/Constants';
import { resources } from 'config/lang/i18n';
import config from 'config/config';
import './FormStyles.css';
import { useDispatch } from 'react-redux';
import { setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';

interface FormRendererProps {
  formSRC?: string;
  lang: string;
}

const FormRenderer: React.FC<FormRendererProps> = ({ lang }) => {
  const [instance, setInstance] = useState<any>(null);
  const [initData, setInitData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const { i18n } = useTranslation<string>();
  const { formsrc, partynumber } = useParams();
  const [formSrc, setFormSrc] = useState<string | undefined>();
  const [formClass, setFormClass] = useState<string>('defaultForm');
  const langRef = React.useRef<{ [key: string]: boolean }>({});
  const data = partynumber ? { partyNumber: partynumber } : undefined;
  const dispatch = useDispatch();

  const fetchLanguageJSON = async () => {
    const response = await fetch(
      `${config.formio.base_url}/dfubjqhqmfquwwk/language/submission?data.language=${i18n.language}`
    );
    const result = await response.json();
    return result[0]?.data.translation;
  };

  const isInstance = () => instance !== undefined && instance !== null;
  const isResourceAvailable = () =>
    resources instanceof Object && !(resources instanceof Array) && (i18n.language as string) in resources;

  const setLanguagePage = () => {
    instance.language = i18n.language;
    const pno = Number(localStorage.getItem('page'));
    instance.setPage(pno);
  };

  useEffect(() => {
    dispatch(setOpenBriefView(false));
    const languageFetch = async () => {
      let translation = await fetchLanguageJSON();
      const optionInit = {
        language: localStorage.getItem('language') || i18n.language,
        i18n: {
          en: resources['en'].translation,
          nl: resources['nl'].translation
        }
      };
      setOption({ ...optionInit });
      if (isResourceAvailable()) {
        const resourceTranslation = resources[i18n.language as 'en' | 'nl' | 'de'].translation;
        translation = { ...translation, ...resourceTranslation };
      }
      if (isInstance()) {
        instance.addLanguage(i18n.language, translation);
        langRef.current[i18n.language] = true;
        setLanguagePage();
      }
    };

    //if (langRef.current[i18n.language] && isInstance()) setLanguagePage();
    //else languageFetch();
    languageFetch();
    const i18nInitConfig = {
      i18n: {
        en: resources['en'].translation,
        nl: resources['nl'].translation
      }
    };
    setOption({ ...i18nInitConfig, ...option, language: i18n.language });

    formSrcMapping.forEach((itm: any) => {
      if (itm.key === formsrc) {
        if (formsrc === 'carpolicyemployeeportal') {
          const selectTheInsurances = {
            selectTheInsurances: {
              PersLiability: false,
              PersAuto: true
            }
          };
          setInitData({ ...initData, ...selectTheInsurances });
        }
        if (formsrc === 'liabilitypolicyemployeeportal') {
          const selectTheInsurances = {
            selectTheInsurances: {
              PersLiability: true,
              PersAuto: false
            }
          };
          setInitData({ ...initData, ...selectTheInsurances });
        }
        setFormSrc(itm.src);
        setFormClass(itm.className || 'defaultForm');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    i18n.language,
    lang,
    instance,
    formsrc
  ]);

  useEffect(() => {
    //dispatch(setOpenBriefView(false));
    Formio.setBaseUrl(config.formio.base_url);
    Formio.setProjectUrl(config.formio.project_url);
    Formio.registerPlugin(Formioprerequest, 'Formioprerequest');
  }, []);

  return (
    <div style={{ width: '100%', padding: '30px' }} className={formClass}>
      <Form
        key={formsrc}
        submission={{ data: { ...data, ...initData } }}
        src={formSrc}
        onChange={(form_instance: any) => {
          setInitData({ ...initData, ...form_instance.data });
        }}
        onRender={(form_instance: any) => {
          console.log(option);
          if (form_instance?.component !== undefined) {
            setInstance(form_instance?.component.root);
            localStorage.setItem('page', form_instance?.component.root.page);
          }
        }}
        options={{ ...option }}
      />
    </div>
  );
};

export default FormRenderer;
