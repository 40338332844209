import { useState } from 'react';
import { IconButton } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export function EyeSwitch() {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsVisible((prev) => !prev);
    alert('Visible : ' + !isVisible);
  };

  return (
    <IconButton onClick={handleToggleVisibility} aria-label={isVisible ? 'Hide' : 'Show'}>
      {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
    </IconButton>
  );
}

export default EyeSwitch;
