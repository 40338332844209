import MiniDrawer from 'component/organisms/Drawer/MiniDrawer';
import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { StyledLayout, Wrapper } from './StyledLayout';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import BriefViewDrawer from 'component/organisms/Drawer/BriefViewDrawer';

type JSXNode = JSX.Element | null;

interface ILayout {
  children?: React.ReactNode;
  handleTheme: (event: any) => void;
  theme: string;
}

const Layout = (props: ILayout): JSXNode => {
  const location = useLocation();
  const showNavBar = !location.pathname.startsWith('/login');
  const navigate = useNavigate();

  // This code is to avoid reloading of the when the anchor tag is clicked;
  const formioEventListeners = () => {
    // Iterate through all anchor tags and bind click event
    document.addEventListener('click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && target.closest('.navi-links')) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const href = target.getAttribute('href');
        const routingUrl = '/formrenderer/' + href;
        navigate(routingUrl);
      }
    });
  };

  useEffect(() => {
    formioEventListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        {showNavBar ? <MiniDrawer handleTheme={props.handleTheme} theme={props.theme} /> : ''}
        <StyledLayout>{props.children}</StyledLayout>
        {showNavBar ? <BriefViewDrawer /> : ''}
      </Wrapper>
      <Box sx={{ width: '100%' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
