import { useTheme } from '@mui/material/styles';
function IconCompanyLogo(_props: any) {
  const theme = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={_props.width ? _props.width : '29'}
      height={_props.height ? _props.height : '29'}
      viewBox='0 0 28 29.265'>
      <path
        fill={_props.color ? _props.color : theme.palette.primary.main}
        fillRule='evenodd'
        d='M6.706 18.302a1.022 1.022 0 00-1.852.864 10.715 10.715 0 100-9.068 1.022 1.022 0 101.852.863 8.673 8.673 0 110 7.341'></path>
      <path
        fill={_props.color ? _props.color : theme.palette.primary.main}
        fillRule='evenodd'
        d='M14.86 19.235l-.189-.01a4.427 4.427 0 01-3.321-1.6.906.906 0 00-1.17-.19.919.919 0 00-.432.639.9.9 0 00.2.727 6.5 6.5 0 004.465 2.222l.247.018q.17.008.332.008a4.84 4.84 0 003.319-1.184 4.138 4.138 0 001.391-2.844c.1-2.043-1.187-3.631-3.45-4.251l-1.1-.3a.947.947 0 00-.651.046.906.906 0 00-.5.606.941.941 0 00.677 1.1l1.094.3a2.423 2.423 0 012.118 2.413c-.078 1.555-1.587 2.336-3.03 2.3'></path>
      <path
        fill={_props.color ? _props.color : theme.palette.primary.main}
        fillRule='evenodd'
        d='M14.797 8.198l-.247-.019a4.923 4.923 0 00-3.653 1.174A4.137 4.137 0 009.51 12.2c-.1 2.043 1.187 3.632 3.45 4.251l1.132.306a.889.889 0 00.192.03h.045a.906.906 0 00.72-1.455.952.952 0 00-.515-.334l-1.1-.3a2.424 2.424 0 01-2.112-2.406c.079-1.567 1.595-2.347 3.047-2.3l.171.009a4.427 4.427 0 013.321 1.6.907.907 0 001.169.19.92.92 0 00.432-.639.9.9 0 00-.2-.727 6.5 6.5 0 00-4.465-2.227'></path>
      <path
        fill={_props.color ? _props.color : theme.palette.primary.main}
        fillRule='evenodd'
        d='M23.539 5.725a12.561 12.561 0 012.512 3.581 1.022 1.022 0 001.849-.863 14.627 14.627 0 100 12.377 1.022 1.022 0 00-1.852-.863 12.6 12.6 0 11-2.509-14.232'></path>
    </svg>
  );
}

export default IconCompanyLogo;
