/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, CircularProgress, FormControl } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { InputLable } from '../InputLabel/InputLabel';
import { keyGenerator } from '../../../utils/Utils';
import SearchService from '../../../entities/Search/SearchService';
import { ISearchResult } from '../../../entities/Search/ISearchResult';
import IconInsuranceSolid from 'component/atoms/Icons/IconInsuranceSolid';
import IconHouse from 'component/atoms/Icons/IconHouse';
import IconInvoice from 'component/atoms/Icons/IconInvoice';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  GroupHeader,
  MenuItemsIcon,
  GroupItems,
  formControlWrapper,
  autocompleteWrapper,
  partyName,
  partyNumber,
  partyDate,
  dividerHeight
} from './SmartsearchStyles';
import { setBriefView, setOpenBriefView } from 'store/briefview/briefViewDrawerSlice';
import PartyBriefView from 'component/pages/Party/PartyBriefView';
import { useDispatch } from 'react-redux';
import PolicyBriefView from 'component/pages/Policy/PolicyBriefView';
import { ClaimBriefView } from 'component/pages/Claim/ClaimBriefView';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import OfferBriefView from 'component/pages/Offer/OfferBriefView';

const randomKey = keyGenerator();

interface ISmartsearch {
  searchVal?: string;
  handleInputChange: Function;
  handleIconClick?: Function;
  label?: string;
}

export default function Smartsearch(props: ISmartsearch) {
  const { t } = useTranslation();
  const service = new SearchService();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<ISearchResult[]>([]);
  const dispatch = useDispatch();

  function handleNavigate(option: ISearchResult, tab: number) {
    switch (tab) {
      case 0:
        dispatch(setBriefView(<PartyBriefView partyNumber={String(option.PartyNumber)} />));
        dispatch(setOpenBriefView(true));
        break;
      case 1:
        dispatch(setBriefView(<OfferBriefView policyNumber={String(option.Key.split('?')[1])} />));
        dispatch(setOpenBriefView(true));
        break;
      case 2:
        dispatch(setBriefView(<PolicyBriefView policyNumber={String(option.Key.split('?')[1])} />));
        dispatch(setOpenBriefView(true));
        break;
      case 3:
        dispatch(setBriefView(<ClaimBriefView claimNumber={String(option.Key)} />));
        dispatch(setOpenBriefView(true));
        break;
      default:
        dispatch(setBriefView(<PartyBriefView partyNumber={String(option.PartyNumber)} />));
        dispatch(setOpenBriefView(true));
        break;
    }
  }

  function fetchSearchResult(search: string) {
    setLoading(true);
    setOpen(false);

    if (!search) {
      setLoading(false);
      setOpen(false);
      setResult([]);
      return;
    }

    service.search(search, 'All', false, true).then((resp: ISearchResult[]) => {
      const flattenedResults = resp.flat();
      setLoading(false);
      setOpen(true);
      setResult(flattenedResults);
    });
  }

  useEffect(() => {
    fetchSearchResult(props.searchVal || '');
  }, [props.searchVal]);

  return (
    <FormControl sx={formControlWrapper} variant='standard'>
      {props.label && (
        <InputLable shrink htmlFor={`${randomKey}MuiInputfield`} sx={{ typography: 'body2' }}>
          {props.label}
        </InputLable>
      )}
      <Autocomplete
        autoComplete={true}
        filterOptions={(x) => x}
        id='smart-search'
        options={result.sort((a, b) => -b?.Domain?.localeCompare(a?.Domain))}
        groupBy={(option) => option.Domain}
        open={open}
        clearOnBlur={false}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => option.CommercialName}
        sx={autocompleteWrapper}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            value={props.searchVal}
            onChange={(e) => props.handleInputChange(e.target.value as string)}
            size='small'
            placeholder={t('smartSearchPlaceholder')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <React.Fragment>
                  <SearchIcon />
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(props, option) => renderOption(props, option)}
        renderGroup={(params) => renderGroup(params)}
      />
    </FormControl>
  );

  function renderOption(props: any, option: ISearchResult) {
    return (
      <li {...props}>
        <Box sx={{ display: 'flex' }} onClick={() => handleNavigate(option, getTabByDomain(option.Domain))}>
          <MenuItemsIcon sx={{ width: '45px', background: 'transparent' }}>
            {getIconByDomain(option.Domain)}
          </MenuItemsIcon>
          <Box sx={partyName}>{option.CommercialName}</Box>
          {'.'}
          <Box sx={partyNumber}>{option.PartyNumber}</Box>
          {'.'}
          <Box sx={partyDate}>{option.Key}</Box>
        </Box>
      </li>
    );
  }

  function renderGroup(params: any) {
    return (
      <li key={params.key}>
        <GroupHeader>{params.group}</GroupHeader>
        <GroupItems>{params.children}</GroupItems>
        <Divider sx={dividerHeight} />
      </li>
    );
  }

  function getTabByDomain(domain: string) {
    switch (domain) {
      case 'Party':
        return 0;
      case 'Offer':
        return 1;
      case 'Policy':
        return 2;
      case 'Claim':
        return 3;
      default:
        return 0;
    }
  }

  function getIconByDomain(domain: string) {
    switch (domain) {
      case 'Party':
        return <VerifiedUserIcon fontSize='small' sx={{ mr: 2 }} />;
      case 'Offer':
        return <IconInvoice />;
      case 'Policy':
        return <IconInsuranceSolid />;
      case 'Claim':
        return <IconHouse />;
      default:
        return <IconInsuranceSolid />;
    }
  }
}
