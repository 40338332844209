export const formControlLabelStyle = {
  border: '1px solid #ccc',
  borderRadius: 1,
  padding: 1,
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  width: 300,
  '&:hover': {
    color: '#00005a',
    backgroundColor: '#f0f8ff'
  }
};
