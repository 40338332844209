import { TogglegroupSwitch } from 'component/atoms/TogglegroupSwitch/TogglegroupSwitch';
import React from 'react';
import { FormControl } from '@mui/material';
import { InputLable } from '../InputLabel/InputLabel';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ToggleButtonStyles, ToggleButtonSwitch } from './TogglebuttonStyle';
import { ToggleButtonProps } from './TogglebuttonProp';

export function Togglebuttons(_props: ToggleButtonProps) {
  const [alignment, setAlignment] = React.useState<string | null>('left');
  const { t } = useTranslation<string>();
  const handleAlignment = (_event: any, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };
  return (
    <Box>
      <FormControl sx={ToggleButtonStyles} fullWidth variant='standard'>
        <InputLable>{t(_props.label)}</InputLable>
        <TogglegroupSwitch value={alignment} exclusive onChange={handleAlignment} aria-label='text alignment'>
          <ToggleButtonSwitch value='left'>{t('yes')}</ToggleButtonSwitch>
          <ToggleButtonSwitch value='center'>{t('no')}</ToggleButtonSwitch>
        </TogglegroupSwitch>
      </FormControl>
    </Box>
  );
}
