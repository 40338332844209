import { useState } from 'react';

import Smartsearch from 'component/organisms/Smartsearch/Smartsearch';
import { Box } from '@mui/material';

export const Action = () => {
  const [searchVal, setSearchVal] = useState('');

  const handleInputChange = (val: any) => {
    setSearchVal(val);
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', p: 2.5, flexGrow: 1 }}>
        <Smartsearch searchVal={searchVal} handleInputChange={handleInputChange}></Smartsearch>
      </Box>
    </>
  );
};
