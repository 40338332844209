import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Inputfield } from '../Inputfield/Inputfield';
import { InputPassword } from '../InputPassword/InputPassword';
import { closableModalStyle } from './ClosableModalStyle';
import { StyledButton } from 'component/atoms/Buttons/ButtonStyle';

export default function LoginModal() {
  const { t } = useTranslation<string>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Stack>
      <StyledButton variant='outlined' sx={{ mb: 2 }} onClick={handleOpen}>
        {t('loginModalClick')}
      </StyledButton>

      <Modal open={open} onClose={handleClose} sx={closableModalStyle}>
        <Box className='ModalBoxStyle'>
          <Stack direction='column' alignItems='left' spacing={2}>
            <Stack spacing={2}>
              <Typography className='titleStyle'> {t('login')} </Typography>
            </Stack>

            <Stack spacing={2}>
              <Inputfield label='Email' />
            </Stack>

            <Stack spacing={2}>
              <InputPassword />
            </Stack>

            <Stack direction='row' alignItems='center' spacing={2}>
              <StyledButton variant='contained' disabled={false}>
                'Inloggen'
              </StyledButton>
              <Link href='#' className='linkStyle'>
                {t('forgotPassword')}
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
}
