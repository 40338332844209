//import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { KeycloakProvider } from 'context/Keycloak/Keycloak';
import { Formio } from 'formiojs';
import premium from '@formio/premium';

import { store } from 'store/store';
import { Provider } from 'react-redux';

Formio.use(premium);

LicenseInfo.setLicenseKey(
  '14ca93d5d9b5fefe4659e5320e5e0be2Tz02MjE3NCxFPTE3MTA1OTUyMjA3ODgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <KeycloakProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </KeycloakProvider>
);

reportWebVitals();
