import { TypographyText } from '../../atoms/Typography/Typography';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
//import useTheme from '@mui/material/styles/useTheme';

const commonStyles = {
  borderColor: 'text.primary',
  m: 1,
  border: 0,
  width: '5rem',
  height: '5rem'
};

export function Palletes() {
  //const theme = useTheme();
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'start', px: 10, py: 5, background: '#f2f5ff' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
          <TypographyText sx={{ p: 2 }} variant='h1'>
            Page Title h1 Open sans bold -24pt
          </TypographyText>
          <TypographyText sx={{ p: 2 }} variant='h2'>
            Card Title h2 Open sans bold -18pt
          </TypographyText>
          <TypographyText sx={{ p: 2 }} variant='subtitle2' display='block' gutterBottom>
            P small Open Sans Bold - 14pt
          </TypographyText>
          <TypographyText sx={{ p: 2 }} variant='body2' display='block' gutterBottom>
            P small Open Sans Regular - 14pt
          </TypographyText>
          <TypographyText sx={{ p: 2 }} variant='caption' display='block' gutterBottom>
            P small Open Sans Regular - 12pt
          </TypographyText>
          <TypographyText sx={{ p: 2 }} variant='overline' display='block' gutterBottom>
            label open sans semibold - 10pt
          </TypographyText>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pr: 3, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: (theme) => theme.palette.primary.main }} />
              <TypographyText sx={{ color: (theme: any) => theme.palette.primary.main }}>
                Brand Color Selection
              </TypographyText>
            </Box>

            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{ ...commonStyles, borderRadius: '50%', bgcolor: (theme: any) => theme.palette.secondary.main }}
              />
              <TypographyText sx={{ color: (theme: any) => theme.palette.secondary.main }}>
                Brand Color Selection
              </TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: (theme: any) => theme.palette.primary.main }} />
              <TypographyText sx={{ color: (theme: any) => theme.palette.primary.main }}>
                {' '}
                UI Color Primary
              </TypographyText>
            </Box>

            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: '50%',
                  bgcolor: (theme: any) => alpha(theme.palette.primary.main, 0.7)
                }}
              />
              <TypographyText sx={{ color: (theme: any) => alpha(theme.palette.primary.main, 0.7) }}>
                UI Color Primary 70%
              </TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: '50%',
                  bgcolor: (theme: any) => alpha(theme.palette.primary.main, 0.5)
                }}
              />
              <TypographyText sx={{ color: (theme: any) => alpha(theme.palette.primary.main, 0.5) }}>
                UI Color Primary 50%
              </TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: '50%',
                  bgcolor: (theme: any) => alpha(theme.palette.primary.main, 0.3)
                }}
              />
              <TypographyText sx={{ color: (theme: any) => alpha(theme.palette.primary.main, 0.3) }}>
                UI Color Primary 30%
              </TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: '50%',
                  bgcolor: (theme: any) => alpha(theme.palette.primary.main, 0.2)
                }}
              />
              <TypographyText sx={{ color: (theme: any) => alpha(theme.palette.primary.main, 0.2) }}>
                UI Color Primary 20%
              </TypographyText>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'top', pr: 3, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: grey[300] }} />
              <TypographyText sx={{ color: grey[300] }}>UI Lines</TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: grey[50] }} />
              <TypographyText sx={{ color: grey[500] }}>Background medium</TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: grey[100] }} />
              <TypographyText sx={{ color: grey[500] }}>Background Light</TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box sx={{ ...commonStyles, borderRadius: '50%', bgcolor: (theme: any) => theme.palette.error.main }} />
              <TypographyText sx={{ color: (theme: any) => theme.palette.error.main }}>UI High Priority</TypographyText>
            </Box>
            <Box sx={{ display: 'flex', pr: 2, alignItems: 'center' }}>
              <Box
                sx={{ ...commonStyles, borderRadius: '50%', bgcolor: (theme: any) => theme.palette.warning.light }}
              />
              <TypographyText sx={{ color: (theme: any) => theme.palette.warning.light }}>
                UI Medium Priority
              </TypographyText>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
