export const closableModalStyle = {
  '&.MuiModal-root .ModalBoxStyle': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 620,
    background: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 8,
    padding: 30,
    '& .IconButtonStyle': {
      borderRadius: '50%',
      backgroundColor: '#f8f8f8',
      padding: 5
    },
    '& .titleStyle': {
      fontWeight: 'bold',
      color: '#00005a'
    },
    '& .linkStyle': {
      textDecoration: 'none',
      underline: 'none',
      color: '#0046ff'
    }
  }
};
