export const emailTypeFeildStyle = {
  fontSize: 14,
  pr: 1,
  height: 43,
  minWidth: 90,
  backgroundColor: '#fff',
  '& .MuiInputBase-root': { pr: 0 },
  'label+.MuiOutlinedInput-root': { mt: 0 },
  '& .selectStyle': {
    backgroundColor: '#f2f2f2',
    boxShadow: 'none',
    mr: 0,
    '& .MuiOutlinedInput-input': { border: 0 },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 }
  },
  '.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-input': { border: 'none' },
  '.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root': { background: '#f2f2f2' },
  '.MuiFormControl-root': { pr: '3px' },
  '.MuiFormControl-root .MuiOutlinedInput-notchedOutline': { border: 'none', borderColor: '#fff' }
};
