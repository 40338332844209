import { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { IParty } from 'entities/Party/IParty';
import PartyService from 'entities/Party/PartyService';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { contentText, getPhoneNumber } from 'utils/Utils';
import Loader from 'component/organisms/Loader/Loader';

interface PartyBriefViewProps {
  partyNumber: string;
}

const PartyBriefView = (props: PartyBriefViewProps) => {
  const [isloading, setisloading] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation<string>();
  const [partyDetails, setPartyDetails] = useState<IParty>();
  const service = new PartyService();

  const getPartyDetails = () => {
    return service
      .get(props.partyNumber ?? '')
      .then((party) => {
        setPartyDetails(party);
        setisloading(false);
      })
      .catch((error: any) => {
        console.error('Error fetching party details:', error);
        setisloading(false);
      });
  };

  useEffect(() => {
    setisloading(true);
    props.partyNumber && getPartyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.partyNumber]);

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading} onClick={() => {}}>
        <Loader></Loader>
      </Backdrop>
      <Box sx={{ p: 3 }}>
        {partyDetails && (
          <Stack direction={'row'} spacing={1} alignItems='center'>
            <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 70, height: 70 }}>
              <Typography>{`${partyDetails?.PreferredName?.charAt(0)}${partyDetails?.Surname?.charAt(0)}`}</Typography>
            </Avatar>

            <Stack direction='column' spacing={1}>
              <Typography sx={{ fontSize: '24px', color: (theme) => theme.palette.primary.main, fontWeight: 'bold' }}>
                {partyDetails?.Surname} {partyDetails?.PreferredName}
              </Typography>

              <Typography sx={{ fontWeight: 800, fontSize: '14px', color: (theme) => theme.palette.primary.main }}>
                #{partyDetails?.PartyNumber || 'N/A'}
              </Typography>
            </Stack>
          </Stack>
        )}

        {partyDetails && (
          <Stack spacing={1} sx={{ mt: 3, mb: 3 }}>
            <Divider />

            <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
              {t('partyDetails')}
            </Typography>

            <Grid container spacing={2}>
              {partyDetails?.PreferredName && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('preferredName')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>
                      {partyDetails?.PreferredName}
                      {', '}
                      {partyDetails?.Surname}
                    </Typography>
                  </Grid>
                </>
              )}

              {partyDetails?.PartyNumber && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('partyNumber')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.PartyNumber}</Typography>
                  </Grid>
                </>
              )}

              {partyDetails?.BirthDt && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('dob')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.BirthDt}</Typography>
                  </Grid>
                </>
              )}

              {partyDetails?.GenderCd && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('gender')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.GenderCd}</Typography>
                  </Grid>
                </>
              )}

              {partyDetails?.PartyTypeCd && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('partyType')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.PartyTypeCd}</Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />
            <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
              {t('addressDetails')}
            </Typography>
            <Grid container spacing={2}>
              {partyDetails?.Addr && partyDetails?.Addr[0]?.PostalCode && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('postalCode')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.Addr[0]?.PostalCode}</Typography>
                  </Grid>
                </>
              )}
              {partyDetails?.Addr && partyDetails?.Addr[0]?.Addr1 && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('address')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.Addr[0]?.Addr1}</Typography>
                  </Grid>
                </>
              )}
              {partyDetails?.Addr && partyDetails?.Addr[0]?.City && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('city')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.Addr[0]?.City}</Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />

            {partyDetails?.Communications && (
              <>
                <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
                  {t('communicationDeatails')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('emailAddress')}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={contentText}>
                      {partyDetails?.Communications?.map((item: any) => item.EmailAddr).join(' ')}
                    </Typography>
                  </Grid>

                  {getPhoneNumber(partyDetails?.Communications)}
                </Grid>
              </>
            )}

            <Divider />
            <Typography sx={{ fontWeight: 800, fontSize: '16px', color: (theme) => theme.palette.primary.main }}>
              {t('paymentDetails')}
            </Typography>
            <Grid container spacing={2}>
              {partyDetails?.Account && partyDetails?.Account[0]?.AccountNumberId && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('ibanNumber')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.Account[0]?.AccountNumberId.Value}</Typography>
                  </Grid>
                </>
              )}
              {partyDetails?.PaymentOption && partyDetails?.PaymentOption[0]?.MethodPaymentRefCd && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{t('paymentMethod')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={contentText}>{partyDetails?.PaymentOption[0]?.MethodPaymentRefCd._Desc}</Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />
            <Grid container spacing={2}>
              {partyDetails?.PartyNumber && (
                <Grid xs={6}>
                  <Typography
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link
                      to={`/party/partydetails/${partyDetails?.PartyNumber}/1`}
                      style={{ color: theme.palette.secondary.main }}>
                      {t('moreDetails')}
                    </Link>
                  </Typography>
                </Grid>
              )}
              {partyDetails?.PartyNumber && (
                <Grid xs={6}>
                  <Typography
                    variant='subtitle2'
                    display='block'
                    sx={{ color: (theme) => theme.palette.primary.main, ml: 2 }}
                    gutterBottom>
                    <Link
                      to={`/party/partydetails/${partyDetails?.PartyNumber}/1`}
                      style={{ color: theme.palette.secondary.main }}>
                      {t('edit')}
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default PartyBriefView;
