import React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { InputLable } from '../InputLabel/InputLabel';
import { emailTypeFeildStyle } from './EmailTypeFeildStyle';
import { OutlinedFormInput } from 'component/atoms/OutlinedInput/OutlinedInput';

export function Emailtypefield() {
  const { t } = useTranslation<string>();

  const [email, setEmail] = React.useState('Work');

  const handleChange = (event: SelectChangeEvent) => {
    setEmail(event.target.value);
  };

  return (
    <FormControl sx={emailTypeFeildStyle} variant='standard'>
      <InputLable htmlFor='email'>{t('email')}</InputLable>
      <OutlinedFormInput
        id='emailOutline'
        endAdornment={
          <InputAdornment sx={{ minWidth: 110 }} position='end'>
            <FormControl variant='outlined' sx={{ minWidth: 110, border: 0, pr: 1.5 }}>
              <Select labelId='email' id='email' value={email} onChange={handleChange} className='selectStyle'>
                <MenuItem selected value={'Work'}>
                  {t('work')}
                </MenuItem>
                <MenuItem value={'Private'}>{t('private')}</MenuItem>
              </Select>
            </FormControl>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
