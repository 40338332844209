import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { Stack } from '@mui/material';
import { IWarningTooltipsProps } from './WarningToolTipProps';
import { WaringIconStyles } from './ToolTipStyles';
import ArrowTooltip from './ArrowTooltip';

const WarningToolTip = (props: IWarningTooltipsProps) => {
  return (
    <ArrowTooltip
      title={
        <>
          <Stack direction='row' alignItems='center' spacing={1}>
            <ReportProblemRoundedIcon fontSize='medium' sx={WaringIconStyles} />
            <Stack>
              <b>{props.title}</b>
              <em>{props.message}</em>
            </Stack>
          </Stack>
        </>
      }
      placement={props.placement}>
      {props.children}
    </ArrowTooltip>
  );
};

export default WarningToolTip;
