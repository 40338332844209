import { Typography, styled } from '@mui/material';
//import InputBase from '@mui/material/InputBase';

const typographyVariants: any = {
  cardTitle: { fontSize: '14px', color: 'white' },
  cardMessage: { fontSize: '11px', color: 'white' },
  caption: { color: '#4c4c88', fontSize: '12px' },
  overline: { color: '#0046ff', fontSize: '10px', fontWeight: 600 },
  body2: { color: '#00005a', fontSize: '14px' },
  subtitle2: { color: '#00005a', fontWeight: 800, fontSize: '14px' },
  h2: { color: '#00005a', fontSize: '18px', fontWeight: 800 },
  h1: { color: '#00005a', fontSize: '24px', fontWeight: 800 }
};
export const TypographyText = styled(Typography)(({ variant }) => typographyVariants[variant || '']);
