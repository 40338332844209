import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridValidRowModel
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { DataGridContainer, DataGridProSx } from './EntityListViewStyles';
import { addActionColumn, actionsColumnExits } from './ActionsColumnExtender';
import { canPerformOperation } from 'api/OperationSupport';

interface IProps<T extends GridValidRowModel> {
  columns: GridColDef[];
  rows: T[];
  getId: (row: T) => string;
  getActions?: (params: GridRowParams<T>) => JSX.Element[];
  columnVisibilityModel?: GridColumnVisibilityModel;
}

const EntityListView = <T extends GridValidRowModel>(props: IProps<T>) => {
  const navigate = useNavigate();

  const { columns, rows, getId, columnVisibilityModel = {}, getActions } = props;

  const editActionHandler = (id: string, readonly: boolean) => {
    const params = readonly ? '?readonly' : '';
    navigate(`${id}${params}`);
  };

  const onRowDoubleClick = (params: GridRowParams<T>) => {
    const id = params.id.toString();

    if (canPerformOperation('edit')) {
      editActionHandler(id, false);
    } else if (canPerformOperation('view')) {
      editActionHandler(id, true);
    }
  };

  if (!actionsColumnExits(columns)) {
    addActionColumn(columns, editActionHandler, getActions);
  }

  return (
    <DataGridContainer>
      <DataGridPro
        sx={DataGridProSx}
        columns={columns}
        rows={rows ?? []}
        getRowId={getId}
        onRowDoubleClick={onRowDoubleClick}
        disableColumnPinning
        pagination
        initialState={{
          columns: { columnVisibilityModel },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pageSizeOptions={[
          10,
          20,
          40
        ]}
      />
    </DataGridContainer>
  );
};

export default EntityListView;
