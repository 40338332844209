import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IRadioButtonGroupProps } from './IRadioButtonGroupProps';
import { formControlLabelStyle } from './RadioButtonGroupStyle';

export default function RadioButtonsGroup(props: IRadioButtonGroupProps) {
  const { label, defaultValue, name, options, onChange } = props;
  const [checkedValue, setCheckedValue] = useState(defaultValue);
  const { t } = useTranslation<string>();

  useEffect(() => {
    setCheckedValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCheckedValue(value);
    onChange(value);
  };

  return (
    <FormControl>
      <FormLabel id={`${label}-${name}`}>{label}</FormLabel>
      <RadioGroup value={checkedValue} name={name} onChange={handleChange}>
        {options.map((option: any) => (
          <FormControlLabel
            sx={formControlLabelStyle}
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <Typography
                sx={{
                  fontWeight: checkedValue === option.value ? 'bold' : 'normal'
                }}>
                {t(option.label)}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
